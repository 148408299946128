import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { HornbillTestParamValues, HORNBILL_TEST_PARAM_KEY } from '@app/shared/hornbill-test-params';

@Injectable({
  providedIn: 'root',
})
export class PrepaidEnrollmentService {
  static readonly STUB_VALIDATE_CLAIM_CODE_SUCCESS_SUBSCRIPTION_MANAGER = {
    success: true,
    subscriptionData: {
      isMembershipManager: true,
      seatsPurchased: 4,
      seatsAvailable: 3,
    },
  };

  static readonly STUB_VALIDATE_CLAIM_CODE_SUCCESS_SEATHOLDER = {
    success: true,
    subscriptionData: {
      isMembershipManager: false,
    },
  };

  static readonly STUB_VALIDATE_CLAIM_CODE_FAILURE = {
    success: false,
    message: 'Invalid access token',
  };

  constructor(private launchDarklyService: LaunchDarklyService) {}

  private featureEnabled$(): Observable<boolean> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED, false);
  }

  isValid$({ queryParams }: ActivatedRouteSnapshot) {
    // TODO: Implement the stub to do validation of the claim code
    const isFailure = [HornbillTestParamValues.Failure, undefined, ''].includes(queryParams[HORNBILL_TEST_PARAM_KEY]);
    return this.featureEnabled$().pipe(map(flagEnabled => flagEnabled && !isFailure));
  }

  isMembershipManager$({ queryParams }: ActivatedRouteSnapshot) {
    const isMembershipManager = queryParams[HORNBILL_TEST_PARAM_KEY] === HornbillTestParamValues.MembershipManager;
    return this.featureEnabled$().pipe(map(flagEnabled => flagEnabled && isMembershipManager));
  }

  isSeatHolder$({ queryParams }: ActivatedRouteSnapshot) {
    const isSeatHolder = queryParams[HORNBILL_TEST_PARAM_KEY] === HornbillTestParamValues.Seatholder;
    return this.featureEnabled$().pipe(map(flagEnabled => flagEnabled && isSeatHolder));
  }

  validateClaimCode$(route: ActivatedRouteSnapshot): Observable<{}> {
    return combineLatest([this.isValid$(route), this.isMembershipManager$(route), this.isSeatHolder$(route)]).pipe(
      map(([isValid, isMembershipManager, isSeatHolder]) => {
        if (isValid && isMembershipManager) {
          return PrepaidEnrollmentService.STUB_VALIDATE_CLAIM_CODE_SUCCESS_SUBSCRIPTION_MANAGER;
        } else if (isValid && isSeatHolder) {
          return PrepaidEnrollmentService.STUB_VALIDATE_CLAIM_CODE_SUCCESS_SEATHOLDER;
        } else {
          return PrepaidEnrollmentService.STUB_VALIDATE_CLAIM_CODE_FAILURE;
        }
      }),
    );
  }
}
