/* Mixpanel Event Names */
export const MP_EVENT_PAGE_VIEWED = 'Page Viewed';

/* Registration Specific MP Constants. Used by Consumer, Pediatric, and B2b Reg */
export const LOGIN_EMAIL_EXISTS_ERROR = 'Email Already Exists';
export const LOGIN_EMAIL_FORM_FIELD = 'Login Email';
export const MODULE_ACCOUNT_CREATION_PAGE = 'Account Creation Page';
export const MODULE_ADDRESS_PAGE = 'Address Page';
export const MODULE_BILLING = 'Billing Page';
export const MODULE_PEDIATRIC_TERMS_OF_SERVICE = 'Peds Terms of Service page';
export const MODULE_PHONE_DOB_SEX_PAGE = 'Phone DOB Sex Page';
export const MODULE_REGISTRATION_CONFIRMATION_PAGE = 'Registration Confirmation Page';
export const MODULE_REGISTRATION_POSTPONEMENT_PAGE = 'Registration Postponement Page';
export const MP_EVENT_CAPACITY_MODAL_VIEWED = 'Reaching Capacity Modal Viewed';
export const MP_EVENT_CAPACITY_MODAL_CLOSED = 'Reaching Capacity Modal Closed';
export const MP_EVENT_CONSUMER_REGISTRATION_PAYMENT_PAGE_VIEWED = 'Consumer Registration Payment Page Viewed';
export const MP_EVENT_FAMILY_SUCCESSFULLY_SUBMITTED = 'Consumer Family Registration Successfully Submitted';
export const MP_EVENT_FEATURE_FLAG_EVALUATED = 'Feature Flag Evaluated';
export const MP_EVENT_FREE_TRIAL_REG_SUCCESSFULLY_SUBMITTED = 'Free Trial Consumer Registration Successfully Submitted';
export const MP_EVENT_GIFT_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Gift Registration Successfully Submitted';
export const MP_EVENT_INVALID_CC_ENTERED = 'Invalid Credit Card Information Added';
export const MP_EVENT_LOGIN_CLICKED = 'Login Clicked';
export const MP_EVENT_PROMO_CODE_AUTO_APPLIED = 'Promotion Code Auto Applied';
export const MP_EVENT_REF_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Referral Registration Successfully Submitted';
export const MP_EVENT_REG_ADD_DISCOUNT_CODE_CLICKED = 'Add Discount Code Clicked';
export const MP_EVENT_REG_CONFIRMATION_APPOINTMENT_CLICKED = 'Book Appointment Clicked';
export const MP_EVENT_REG_CONFIRMATION_SKIP_FOR_NOW_CLICKED = 'Skip For Now Clicked';
export const MP_EVENT_REG_CONFIRMATION_SURVEY_CLICKED = 'Health History Survey Started';
export const MP_EVENT_REG_DISCOUNT_CODE_APPLIED = 'Discount Code Applied';
export const MP_EVENT_REG_INPUT_ERROR_OCCURRED = 'Input Error Encountered';
export const MP_EVENT_REG_INPUT_SUBMITTED = 'Registration Input Submitted';
export const MP_EVENT_REG_POSTPONEMENT_LEARN_MORE_CLICKED = 'Learn More Clicked';
export const MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_LINK_SELECTED = 'Register Child Link Selected';
export const MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_MODAL = 'Child Registration Modal Viewed';
export const MP_EVENT_REG_REDIRECT_TO_PEDIATRIC_REG_MODAL_DISMISSED = 'Child Registration Modal Dismissed';
export const MP_EVENT_REG_SUCCESSFULLY_SUBMITTED = 'Consumer Registration Successfully Submitted';
export const MP_EVENT_REG_ACCORDION_FAQ_ANSWER_LINK_CLICKED = 'FAQ Answer Link Clicked';
export const MP_EVENT_REG_ACCORDION_FAQ_COLLAPSED = 'FAQ Question Collapsed';
export const MP_EVENT_REG_ACCORDION_FAQ_EXPANDED = 'FAQ Question Expanded';
export const MP_EVENT_REG_ACCORDION_FAQ_READ_LESS_CLICKED = 'FAQ Read Less Clicked';
export const MP_EVENT_REG_ACCORDION_FAQ_READ_MORE_CLICKED = 'FAQ Read More Clicked';
export const MP_EVENT_VIRTUAL_OFFERING_MODAL_VIEWED = 'Virtual Services Modal Viewed';
export const MP_EVENT_VIRTUAL_OFFERING_MODAL_CLOSED = 'Virtual Services Modal Closed';
export const MP_FLOW_REGISTRATION = 'Registration';
export const SUBMODULE_CAPACITY_MODAL = 'Reaching Capacity Modal';
export const SUBMODULE_VIRTUAL_OFFERING_MODAL = 'Virtual Services Modal';
export const SUBMODULE_CHILD_REGISTRATION_MODAL = 'Child Registration Modal';

/* Enterprise Registration */
export const MODULE_DIRECT_SIGN_UP_SELECTION_PAGE = 'Direct Sign Up Selection Page';
export const MODULE_DIRECT_SIGN_UP_FAMILY_PAGE = 'Direct Sign Up Family Page';
export const MODULE_EMAIL_VERIFICATION_PAGE = 'Email Verification Page';
export const MODULE_ENTERPRISE_REGISTRATION_CONFIRMATION_PAGE = 'Enterprise Registration Confirmation Page';
export const MODULE_CONCIERGE_PAGE = 'Concierge Page';
export const MODULE_ACCOUNT_SETUP_PAGE = 'Account Setup Page';
export const MODULE_VERIFIED_ACCOUNT_SETUP_PAGE = 'Pre-Filled Account Setup Page';
export const MODULE_COMPANY_CODE_PAGE = 'Company Code Page';
export const MODULE_SCHEDULE_VISIT_PAGE = 'Schedule Visit Page';
export const MODULE_DOB_SEX_PAGE = 'DOB Sex Page';
export const MODULE_EID_VALIDATION_PAGE = 'EID Validation Page';
export const MODULE_CHOOSE_RELATIONSHIP_TYPE_PAGE = 'Choose Relationship Type Page';
export const MODULE_SERVICE_AREA_PAGE = 'Service Area Page';
export const MODULE_LEGAL_DOCUMENT_PAGE = 'Legal Document Page';
export const MODULE_WORK_EMAIL_VALIDATION_PAGE = 'Work Email Validation Page';
export const MODULE_PERSONAL_DETAILS_PAGE = 'Personal Details Page';
export const MODULE_CHILD_MEMBERSHIP_CONFIRMATION_PAGE = 'Child Membership Confirmation Page';
export const MODULE_APPOINTMENT_CONFIRMATION_PAGE = 'Appointment Confirmation Page';
export const MODULE_VERIFIED_EXISTING_USER_PAGE = 'HRIS Existing Member Redirect Page';
export const PAGE_APPOINTMENT_INVENTORY = 'Appointment Inventory Page';
export const MODULE_VERIFIED_ENTERPRISE_REGISTRATION_LOADING_PAGE = 'HRIS New Member Redirect Page';
export const MODULE_VERIFIED_EXPIRED_TOKEN_PAGE = 'HRIS Session Expired';

/* Action Items/Patient Tasks */
export const MODULE_ALL_TASKS_PAGE = 'All Tasks Page';

/* Enterprise Post-Registration Appointment Recommendation Experiment */
export const ISSUE_REFINEMENT_PAGE = 'Issue Refinement Page';
export const ISSUE_SELECTION_PAGE = 'Issue Selection Page';

/* Membership Settings */
export const MODULE_SETTINGS_PAGE = 'MyOne Membership Settings Page';

/* Membership Settings */
export const ACCOUNT_SECURITY_PAGE = 'Account Security Page';

/* Email Verification */
export const MP_EVENT_EMAIL_VERIFIED = 'Email Successfully Verified';
export const MP_EVENT_EMAIL_UNVERIFIED = 'Email Unsuccessfully Verified';
export const MP_EVENT_EMAIL_VERIFICATION_ACTION = 'Email Verification Action Selected';
export const MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED = 'Account Locked';
export const MP_EVENT_EMAIL_VERIFICATION_SENT = 'Verification Email Sent';
export const MP_EVENT_EDIT_EMAIL = 'Edit Email Clicked';
export const MP_EVENT_EDIT_EMAIL_ACTION = 'Edit Email Action Selected';
export const MP_EVENT_ADD_PREFERRED_EMAIL = 'Add Preferred Email Clicked';
export const MP_EVENT_ADD_PREFERRED_EMAIL_ACTION = 'Add Preferred Email Action Selected';
export const SUBMODULE_CODE_MODAL = 'Email Verification Modal';

export enum MP_EVENT_EMAIL_VERIFICATION_ACTION_TYPE {
  CONTINUE = 'Continue',
  EMAIL = 'techsupport@onemedical.com',
  SKIP = 'Skip for now',
  RESEND = 'Resend email',
}

export const MODULE_HOME_PAGE = 'MyOne Home Page';
export const SUBMODULE_EMAIL_VERIFICATION_CAROUSEL_CARD = 'Email Verification Onboarding Carousel Card';
export const EMAIL_VERIFICATION_OUTREACH_EMAIL = 'Email Verification Outreach Email';

/* Legacy Provider Team Page aka PCP Overview Page */
export const MODULE_PCP_OVERVIEW_PAGE = 'PCP Overview Page';

/* Messages */
export const MODULE_MESSAGES_OVERVIEW_PAGE = 'Messages Overview Page';
export const MESSAGES_CLICKED = 'Messages Clicked';
export const FLOW_MESSAGES = 'Messages';
export const SUBMODULE_CARE_TEAM_MESSAGES_BUTTON = 'Care Team Messages Button';
export const TIMELINE_POST_CREATED = 'Timeline Post Created';

/* Flows */
export const FLOW_ACCOUNT_UPDATE = 'Account Update';
export const FLOW_HOME_PAGE_NAVIGATION = 'Home Page Navigation';
export const FLOW_LOGIN = 'Login';
export const FLOW_POST_REGISTRATION = 'Post Registration';
export const FLOW_ONBOARDING = 'Onboarding';
export const FLOW_APPOINTMENT_BOOKING = 'Appointment Booking';
export const FLOW_APPOINTMENT_RESCHEDULE = 'Reschedule Visit';
export const FLOW_PATIENT_TASKS = 'Patient Tasks';
export const FLOW_HELP_CENTER = 'Help Center';

/* Modals */
export const PRESCRIPTION_TREAT_ME_NOW_MODAL = 'Prescription TMN';
export const EVENT_TREAT_ME_NOW_MODAL_CLOSED = 'TMN Modal Closed';
export const REFERRAL_MODAL = 'MyOne Friends and Family Referral Modal';

/* Login */
export const LOGIN_STARTED = 'Login Started';

/* MFA */
export const MP_EVENT_MFA_STATUS_CHANGE = 'Two Factor Authentication Updated';
export const MP_EVENT_MFA_RECOVERY_CODE_REGENERATION = 'Regenerate Recovery Code Clicked';
export const MP_EVENT_MFA_RECOVERY_CODE_COPIED = 'Copy Recovery Code Clicked';
export const MP_EVENT_MFA_DISABLE_CLICKED = 'Disable Two Factor Authentication Clicked';
export const MP_EVENT_MFA_DISABLE_CANCELED = 'Disable Two Factor Authentication Canceled';
export const MP_USER_PROFILE_MFA_PROPERTY_NAME = 'Twofactor Authentication Enabled';

/* Home */
export const EVENT_BANNER_CLICKED = 'Banner Clicked';
export const SUBMODULE_COVID_RESOURCES = 'Covid Resources';
export const MOBILE_ENGAGEMENT = 'Mobile Engagement';
export const EVENT_BOOK_VISIT_CLICKED = 'Book Visit Clicked';
export const EVENT_SCHEDULE_VISIT_CLICKED = 'Schedule Visit Clicked';
export const EXPLORE_OTHER_APPOINTMENTS_CLICKED = 'Explore Other Appointments Clicked';
export const EVENT_RECOMMENDED_SERVICES_CARD_CLICKED = 'Recommended Services Card Clicked';
export const JOIN_REMOTE_VISIT_CLICKED = 'Join Remote Visit Clicked';
export const APPOINTMENT_DIRECTIONS_CLICKED = 'Appointment Directions Clicked';
export const VIEW_APPOINTMENT_DETAILS_CLICKED = 'View Appointment Details Clicked';
export const RUNNING_LATE_APPOINTMENT_CLICKED = 'Running Late Appointment Clicked';
export const APPOINTMENT_MARKED_AS_RUNNING_LATE = 'Appointment Marked As Running Late';
export const BOTTOM_GROWTH_BANNER = 'Bottom Growth Banner';
export const MYONE_HEADER_BANNER = 'MyOne Header Banner';
export const HEADER_BANNER = 'Header Banner';
export const REMINDER_CLICKED = 'Reminder Clicked';
export const TRAVEL_RECOMMENDATIONS_CLICKED = 'Travel Recommendations Clicked';
export const FSA_REIMBURSEMENT_CLICKED = 'FSA Reimbursement Clicked';
export const DOWNLOAD_APP_CLICKED = 'Download App Clicked';
export const SUBMODULE_BOOK_VISIT_BUTTON = 'Book Visit Button';
export const SUBMODULE_DOWNLOAD_APP_HERO_BANNER = 'Download App Hero Banner';

/* Navbar */
export const NAVIGATION_BAR = 'Navigation Bar';

/* Health Record */
export const MODULE_LAB_RESULT_DETAILS_PAGE = 'Lab Result Details Page';

/* My Provider PCP Selection & Experiment */
export const EVENT_PCP_SELECTED = 'PCP Selected';
export const EVENT_PROVIDER_HUB_APPT_CARD_CLICKED = 'Appointment Type Card Clicked';
export const EVENT_PROVIDER_HUB_ALL_SET_CLICKED = "I'm All Set For Now Clicked";
export const EVENT_VIEW_MORE_PROVIDERS_CLICKED = 'View More Providers Clicked';
export const MODULE_PCP_OVERVIEW = 'PCP Overview Page';
export const MODULE_PCP_SELECTION_PAGE = 'PCP Selection Page';
export const MODULE_PCP_SELECTED_MODAL_PAGE = 'PCP Selected Modal Page';
export const MODULE_PCP_CARE_OPTIONS_PAGE = 'PCP Care Options Page';
export const FLOW_PCP_SELECTION = 'PCP Selection';
export const SOURCE_PCP_OVERVIEW_PAGE = 'PCP Overview Page';
export const MANAGE_PRESCRIPTIONS_CLICKED = 'Manage Prescriptions Clicked';
export const PRESCRIPTION_MANAGEMENT = 'Prescription Management';
export const MODULE_PRESCRIPTION_RENEWAL_PAGE = 'Prescription Renewal Page';
export const TREAT_ME_NOW_CLICKED = 'Treat Me Now Clicked';
export const VIDEO_CHAT_CLICKED = 'Video Chat Clicked';
export const SEND_DOWNLOAD_LINK_CLICKED = 'Send Download Link Clicked';
export const SKIP_DOWNLOAD_LINK_CLICKED = 'Skip Download Link Clicked';
export const MOBILE_ENGAGEMENT_MODAL_CLOSED = 'Mobile Engagement Modal Closed';
export const MODULE_HELP_CENTER = 'Help Center Page';

/* Appointments */
export const SUBMODULE_LOCATION_SELECTION = 'Location Selection Page';
export const APPOINTMENT_CANCEL_CLICKED = 'Cancel Appointment Clicked';
export const APPOINTMENT_CANCELLED = 'Appointment Cancelled';
export const MODULE_SWITCH_CANCEL_TO_REMOTE_PAGE = 'Switch Cancel to Remote Page';
export const MODULE_SWITCH_RESCHEDULE_TO_REMOTE_PAGE = 'Switch Reschedule to Remote Page';
export const SWITCH_TO_REMOTE_VISIT_CLICKED = 'Attend Over Video Clicked';
export const MEMBERSHIP_CANCELLATION_PAGE = 'Membership Cancellation Page';
export const APPOINTMENT_RESCHEDULE_CLICKED = 'Reschedule Appointment Clicked';

/* Mental Health Topic Routing */
export const MODULE_MENTAL_HEALTH_TOPIC_ROUTING_PAGE = 'Mental Health Topic Routing Page';

/* Family Account Management & Account Switching */
export const FLOW_FAMILY_ACCOUNT_MANAGEMENT = 'Family Account Management';
export const MP_EVENT_SWITCH_ACCOUNT_CLICKED = 'Switch Account Clicked';
export const MP_SWITCH_ACCOUNT_TO_PRINCIPAL = 'Parent Account';
export const MP_SWITCH_ACCOUNT_TO_TARGET = 'Child Account';

/* Referrals */
export const MP_EVENT_FRIENDS_AND_FAMILY_REFERRAL_STARTED = 'Friends and Family Referral Started';
