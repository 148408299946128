import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';

import { LinksService } from '@app/core/links.service';
import { PrepaidEnrollmentService } from '@app/registration/prepaid-enrollment.service';

@Injectable()
export class RegistrationExternalPaymentGuardService implements CanActivate {
  constructor(
    private router: Router,
    private linkService: LinksService,
    private prepaidEnrollmentService: PrepaidEnrollmentService,
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const currentQueryParams = activatedRouteSnapshot.queryParams;
    return this.prepaidEnrollmentService.validateClaimCode$(activatedRouteSnapshot).pipe(
      map((response: { success: boolean }) => {
        const validPrepaidEnrollment = response.success;
        if (validPrepaidEnrollment) {
          // TODO:BHX: Check not in existing hornbill household
          return this.router.createUrlTree([this.linkService.registrationExternalPaymentConversion], {
            queryParams: currentQueryParams,
          });
        }
        return true;
      }),
    );
  }
}
