import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';

import { WindowService } from '@app/utils/window.service';

import { AttemptedPathService } from './attempted-path.service';
import { AuthServiceImplementation } from './auth.service';
import { Auth0AnalyticsService } from './auth0-analytics.service';
import { Auth0Client } from './auth0-client.service';
import { LinksService } from './links.service';
import { OnelifeAuthService } from './onelife-auth.service';
import { StorageService } from './storage.service';

// **** This Injectable should only be accessed through the AuthService ****

@Injectable({
  providedIn: 'root',
})
/**
 * This Class is specifically for handling the onelife implementation of authentication
 * We are moving away from this towards auth0; separating the implementation from the
 * interface will allow us to seamlessly switch to a new implementation
 */
export class Auth0AuthService implements AuthServiceImplementation {
  constructor(
    private analyticsService: Auth0AnalyticsService,
    private attemptedPathService: AttemptedPathService,
    private storageService: StorageService,
    private windowService: WindowService,
    private auth0Client: Auth0Client,
    private linksService: LinksService,
  ) {}

  init() {
    const path = this.windowService.getLocationPathname();
    // Call when app reloads after user logs in with Auth0
    const params = this.windowService.getLocationSearch();
    if (params.includes('code=') && params.includes('state=')) {
      return this.handleAuthCallback();
    } else if (this.attemptedPathService.hasAttemptedPath() && path === this.linksService.home) {
      this.attemptedPathService.navigateToAttemptedPath();
    } else {
      this.attemptedPathService.deleteAttemptedPath();
    }

    return this.auth0Client.isAuthenticated$();
  }

  getToken() {
    // if there already is a token in a cookie, then use that
    // this is for a bug on registration we get a doorkeeper token from our server &
    // are not logged in with auth0 client in this webapp.
    // In order to not be logged out, we need to use that token
    const cookieToken = this.tokenFromCookie();
    if (cookieToken) {
      return observableOf(cookieToken);
    } else {
      return this.auth0Client.getToken$().pipe(
        catchError(err => {
          console.error(err);
          return observableOf(null);
        }),
      );
    }
  }

  goLogin(path: string = null) {
    if (path) {
      this.attemptedPathService.setAttemptedPath(path);
    }

    if (path?.endsWith(this.linksService.verifyEmail)) {
      this.analyticsService.trackLoginStartedFromEmailVerification();
    } else {
      this.analyticsService.trackLoginStarted();
    }

    this.auth0Client.login();
  }

  mfaEnroll(path: string) {
    this.attemptedPathService.setAttemptedPath(path);
    this.auth0Client.enrollInMfa();
  }

  // loginBeforeRedirect argument included for unified interface between auth0 & onelife auth services
  logout(path?: string, _loginBeforeRedirect = true): void {
    this.storageService.removeItem(OnelifeAuthService.TOKEN_KEY);
    this.storageService.removeTldItem(OnelifeAuthService.TOKEN_KEY);

    // this path is needed to support registration usecases
    if (path) {
      this.attemptedPathService.setAttemptedPath(path);
    }
    this.auth0Client.logout();
  }

  setToken(token: string) {
    // ensure oldmyone authentication works as expected
    this.storageService.setTldItem(OnelifeAuthService.TOKEN_KEY, token);
  }

  isConfigured() {
    return this.auth0Client.isConfigured();
  }

  private tokenFromCookie() {
    return this.storageService.getItem(OnelifeAuthService.TOKEN_KEY);
  }

  private handleAuthCallback(): Observable<boolean> {
    this.analyticsService.trackLoginCompleted();
    const authComplete$ = this.auth0Client.getAuthResult$().pipe(
      tap(() => this.attemptedPathService.navigateToAttemptedPath()),
      concatMap(() => this.auth0Client.isAuthenticated$()),
      shareReplay(1),
    );
    authComplete$.subscribe();
    return authComplete$;
  }
}
